import {
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { format, isAfter, isBefore } from "date-fns";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import appStore from "store";

const CalendarContent = ({ selectedDays, setSelectedDays }) => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { locale } = router;

  const { tempSelectedCarIds, configs, latestOrderTimeUnion, timeOptsUnion } =
    appStore;

  const latest_order_time = useMemo(() => {
    if (tempSelectedCarIds.length === 0) return 0;
    const configList = configs.filter((config) =>
      tempSelectedCarIds.some((id) => config.car_ids.includes(id))
    );
    const minLatestOrderTime = configList.reduce((prev, cur) => {
      return prev.latest_order_time < cur.latest_order_time ? prev : cur;
    }).latest_order_time;

    return minLatestOrderTime / 60;
  }, [configs, tempSelectedCarIds]);

  const minDate = useMemo(() => {
    const now = new DateObject();

    const isAfter20 =
      now.valueOf() >
      new DateObject().set({ hour: 20, minute: 0, second: 0 }).valueOf();

    if (isAfter20) {
      return new DateObject().add(1, "days");
    } else {
      return now;
    }
  }, []);

  const mapDays = useMemo(() => {
    const disableDate = ({ date }) => {
      // 時間在 1/25 到 2/2 之間的話，全部disable
      const isBetween =
        isBefore(new Date(date.format()), new Date("2025/02/03")) &&
        isAfter(new Date(date.format()), new Date("2025/01/24"));
      if (isBetween) {
        return {
          disabled: true,
          style: { color: "#ccc" },
        };
      }
    };
    return disableDate;
  }, []);

  // 如果選擇的日期是今天，且現在時間在 20:00 之前，設定 selectedStartClock 為現在的下一個時段
  useEffect(() => {
    if (selectedDays.length !== 0) {
      // 如果開始日期是今天
      const now = new DateObject();
      if (selectedDays[0].format("YYYY-MM-DD") === now.format("YYYY-MM-DD")) {
        // 如果現在時間在 20:00 之前，設定 selectedStartClock 為現在的下一個時段
        if (now.format("HH") < "20") {
          const nextTimeOpt = `${timeOptsUnion.find(
            (timeOpt) => timeOpt > now.format("HH")
          )}:00`;
          appStore.selectedStartClock = nextTimeOpt;
          appStore.selectedEndClock = nextTimeOpt;
          appStore.tempSelectedStartClock = nextTimeOpt;
          appStore.tempSelectedEndClock = nextTimeOpt;
        }
      } else {
        appStore.selectedStartClock = "09:00";
        appStore.selectedEndClock = "09:00";
        appStore.tempSelectedStartClock = "09:00";
        appStore.tempSelectedEndClock = "09:00";
      }
    }
  }, [selectedDays, timeOptsUnion]);

  const isAfter20 = isAfter(new Date(), new Date("2024/06/19 15:59:59"));

  return (
    <Box>
      <Text fontSize="32px" fontWeight="700" lineHeight="40px" mb="24px">
        {t("choose")}
        {selectedDays && selectedDays.length === 1
          ? t("returnTime")
          : t("date")}
      </Text>
      <Calendar
        mapDays={mapDays}
        range={true}
        numberOfMonths={1}
        minDate={minDate}
        currentDate={minDate}
        value={selectedDays}
        onChange={setSelectedDays}
      />
      {!isAfter20 && (
        <Box mt="16px">
          <Flex mt="8px">
            <Text
              color="#3274F6"
              fontSize="20px"
              mt="2px"
              mr="5px"
              lineHeight="20px"
              fontWeight="700"
            >
              •
            </Text>
            <Box as="span" textAlign="start">
              <Text
                textAlign="start"
                fontSize="14px"
                lineHeight="20px"
                fontWeight="700"
                as="span"
                color="#3274F6"
              >
                {t("M3_hl")}{" "}
              </Text>
              <Text
                fontSize="14px"
                lineHeight="20px"
                as="span"
                cursor="pointer"
              >
                {t("M3_hl_info")}
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

const TestHomeTimeSelectBoxV2 = ({ values, setValues, ...props }) => {
  const { t } = useTranslation("common");
  const isMdUp = useBreakpointValue({ base: false, md: true });
  const [tempSelectedDays, setTempSelectedDays] = useState([]);

  const {
    isOpen: isOpenCalendarPopover,
    onClose: onCloseCalendarPopover,
    onOpen: onOpenCalendarPopover,
  } = useDisclosure();

  const {
    isOpen: isOpenCalendarModal,
    onClose: onCloseCalendarModal,
    onOpen: onOpenCalendarModal,
  } = useDisclosure();

  const submitSelectedDays = () => {
    if (isMdUp) {
      if (values.length === 1) {
        const start = new DateObject(values[0]);
        const end = start.add(2, "days");
        setValues([values[0], end]);
      }
      onCloseCalendarPopover();
    } else {
      if (tempSelectedDays.length === 1) {
        // 設定結束時間為開始時間 + 2天
        const start = new DateObject(tempSelectedDays[0]);
        const end = start.add(2, "days");
        setValues([tempSelectedDays[0], end]);
        setTempSelectedDays([tempSelectedDays[0], end]);
      } else {
        setValues(tempSelectedDays);
      }
      onCloseCalendarModal();
    }
  };

  return (
    <Box flex="1" {...props}>
      <Flex>
        <Modal
          isOpen={isOpenCalendarModal}
          onClose={onCloseCalendarModal}
          size="full"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <CloseButton
                variant="ghost"
                size="lg"
                onClick={onCloseCalendarModal}
                ml="-8px"
              />
            </ModalHeader>
            <ModalBody>
              <CalendarContent
                selectedDays={tempSelectedDays}
                setSelectedDays={setTempSelectedDays}
              />
              <Button
                mt="24px"
                w="100%"
                color="white"
                bg="rgba(51, 116, 246, 1)"
                onClick={submitSelectedDays}
              >
                {t("complete")}
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Box flex="1">
          <Popover
            isOpen={isOpenCalendarPopover}
            zIndex="9999"
            placement="bottom-start"
            onClose={submitSelectedDays}
          >
            <PopoverTrigger>
              <Box px="8px" py="4px">
                <Flex
                  py="6px"
                  cursor="pointer"
                  alignItems="center"
                  justify="space-between"
                  onClick={() => {
                    if (isMdUp) {
                      onOpenCalendarPopover();
                    } else {
                      onOpenCalendarModal();
                    }
                  }}
                >
                  <Text>
                    {values[0]?.format("MM/DD")} - {values[1]?.format("MM/DD")}
                  </Text>
                </Flex>
              </Box>
            </PopoverTrigger>
            {isMdUp && (
              <PopoverContent bg="white" flex="1" width="400px" zIndex="9999">
                <PopoverArrow />
                <PopoverBody p="24px">
                  <CalendarContent
                    selectedDays={values}
                    setSelectedDays={setValues}
                  />
                </PopoverBody>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      </Flex>
    </Box>
  );
};
export default TestHomeTimeSelectBoxV2;
